<template>
  <div>
    <ul class="entry-list">
      <li class="list-item" v-for="entry in entryListItems" :key="entry.id">
        <router-link :to="entry.id">
          <div class="left">
            <h2>{{ entry.title }}</h2>
            <span>{{ entry.description }}</span>
          </div>
          <div class="right">
            <span>{{ entry.date.toLocaleDateString('en-AU', { year: 'numeric', month: 'long', day: 'numeric' }) }}</span>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['entryListItems']
}
</script>

<style lang="scss" scoped>
@use '@/styles/_presets.scss';
@import '@/styles/_colors.scss';

.entry-list {
  width: 80vw;
  margin: auto;
  padding: 0;
}

.list-item {
  list-style-type: none;
  border: 1px solid $primary;
  break-inside: avoid-column;
  margin-left: -1px;
  margin-top: -1px;
  transition: background-color 0.3s ease-in-out;
  background-color: white;

  &:hover {
    background-color: $secondary;
  }

  a {
    @extend h2;
    display: flex;
    @media screen and (max-width: 600px) { flex-direction: column; justify-content: center; }
    justify-content: space-between;
    &.center {
      justify-content: center;
    }
    align-items: center;
    padding: 2vh;
    text-decoration: none;
    flex-wrap: wrap;
    min-height: 5em;
  }
}

.left {
  @media screen and (max-width: 600px) { text-align: center; max-width: 100%; }
  max-width: 70%;
  text-align: left;
  span {
    font-size: 0.8em;
  }
}

.right {
  color: $subtitle;
}
</style>
