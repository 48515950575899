<template>
  <div>
    <EntryList :entryListItems="entries" />
  </div>
</template>

<script>
import newsentries from '@/views/news/news.js'
import EntryList from '@/components/EntryList.vue'

export default {
  components: {
    EntryList
  },
  computed: {
    entries () {
      return newsentries.sort((a, b) => b.date - a.date)
    }
  }
}
</script>
